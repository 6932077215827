.link {
  text-decoration: none !important;
  color: var(--dark) !important;
  transition: 0.3s all ease-in-out;
}

.link:hover {
  cursor: pointer;
  color: var(--primary) !important;
  transition: 0.3s all ease-in-out;
}

.img_wrapper {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  height: 45px !important;
}
.chart {
  max-height: 350px;
  transition: 0.3s all ease-in-out;
}
.searchInput {
  max-width: 400px;
  width: 100%;
  max-height: 40px;
  border-radius: 30px !important;
  margin: 0 20px;
}
.add {
  font-size: 28px;
  color: var(--dark);
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

.chart_icon {
  color: var(--primary);
  font-size: 20px;
  transition: 0.3s all ease-in-out;
  margin: 0 10px;
}

.chart_icon:hover {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.add:hover {
  color: var(--primary);
  transition: 0.3s all ease-in-out;
}
@media screen and (max-width: 767px) {
  .line {
    width: 100% !important;
    height: 3px !important;
    margin: 7px 0;
  }
}
