.link {
  text-decoration: none !important;
  color: var(--dark) !important;
  transition: 0.3s all ease-in-out;
}

.link:hover {
  cursor: pointer;
  color: var(--primary) !important;
  transition: 0.3s all ease-in-out;
}

.img_wrapper {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  height: 45px !important;
}
.bdg {
  border: 1px solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: var(--primary);
  padding: 0 10px;
  border-radius: 15px;
  margin-left: 10px;
  transition: 0.3s all ease-in-out;
}

.chart_icon {
  color: var(--primary);
  font-size: 20px;
  transition: 0.3s all ease-in-out;
  margin: 0 10px;
}

.chart_icon:hover {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.bdg:hover {
  border: 1px solid var(--dark);
  color: var(--dark);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.bdg_active {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #fff;
  padding: 0 10px;
  border-radius: 15px;
  margin-left: 10px;
}

.searchInput {
  max-width: 400px;
  width: 100%;
  max-height: 40px;
  border-radius: 30px !important;
  margin: 0 20px;
}
.add {
  font-size: 28px;
  color: var(--dark);
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

.add:hover {
  color: var(--primary);
  transition: 0.3s all ease-in-out;
}
@media screen and (max-width: 767px) {
  .line {
    width: 100% !important;
    height: 3px !important;
    margin: 7px 0;
  }
}
@media print {
  .print {
    font-size: 8px !important;
  }

  .print_heading {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 10px !important;
  }

  .print_table {
    margin-top: 0 !important;
  }

  .tbl_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }

  .heading h4 {
    display: none !important;
  }

  .heading h5 {
    font-size: 14px !important;
  }

  .heading span {
    font-size: 12px !important;
  }

  .print_table_total {
    border: 1px solid var(--primary) !important;
  }
}
